const isHybridge = process.env.NEXT_PUBLIC_APP === "hybridge";

const pages = {
  "*": ["shared"],
  "/": ["home"],
  "/aiaas/2d-simulation": ["aiaas"],
  "/aiaas/2d-smile-design": ["aiaas"],
  "/aiaas/landmarks-detection": ["aiaas"],
  "/aiaas/photo-alignment": ["aiaas"],
  "/aiaas/case-difficulty-estimation-from-stl": ["aiaas"],
  "/aiaas/face-scan-alignment": ["aiaas"],
  "/aiaas/photo-classification": ["aiaas"],
  "/aiaas/deprecated/photo-editing": ["aiaas"],
  "/aiaas/smile-design-generation": ["aiaas"],
  "/aiaas/teeth-labeling": ["aiaas"],
  "/aiaas/teeth-segmentation": ["aiaas"],
  "/aiaas/texture-extraction": ["aiaas"],
  "/geniova": ["aiaas"],
  "/generic": ["aiaas"],
  "/globalD": ["aiaas"],
  "/straumann": ["aiaas"],
  "/c4w": ["aiaas"],
  "/contact": ["contact"],
  "/news": ["news"],
  "/products/smile": ["productsSmile"],
  "/smile": ["smile"],
  "/smile/password-forgotten": ["smile"],
  "/smile/restore": ["smile"],
  "/smile/signin": ["smile"],
  "/smile/signup": ["smile"],
  "/smile/signup-success": ["smile"],
  "/terms-and-conditions": ["termsAndConditions"],
  "/terms-of-use": ["termsOfUse"],
};

module.exports = {
  allLanguages: isHybridge ? ["en"] : ["en", "fr"],
  defaultLanguage: "en",
  currentPagesDir: isHybridge ? "src/pages_.hybridge" : "src/pages_",
  finalPagesDir: "src/pages",
  localesPath: "public/locales",
  pages: isHybridge
    ? {
        "*": pages["*"],
        "/": pages["/smile"],
        "/password-forgotten": pages["/smile/password-forgotten"],
        "/restore": pages["/smile/restore"],
        "/signin": pages["/smile/signin"],
        "/signup": pages["/smile/signup"],
        "/signup-success": pages["/smile/signup-success"],
      }
    : pages,
};
